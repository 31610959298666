import React from 'react'
import { Box, Text, Flex, Link, Image } from '@chakra-ui/react'
import GLink from 'gatsby-link'
import Img from 'gatsby-image'
import logo from "../../images/logo.png"

function Header(page2) {
    return (
        <Flex align={'center'} justify={'center'} h={'10vh'} w={"100vw"} backgroundColor={"#CCCCCC00"} pos={"fixed"}>
            <Box w={"4vw"}>
                <Image src={logo}/>
            </Box>
            <Box p={8} pl={0}>

                <Text fontSize="2xl" color={"white"}>Overtime High</Text>
            </Box>
            <Flex w={"40vw"}/>
            <Flex w={"30vw"}>
            <Link  m={'auto'} color={"white"} >
                <GLink to={'/'}>
                    Home
                </GLink>
            </Link>
            <Link m={'auto'} color={"white"}>
                <GLink to={'/aspacebetween'}>
                    A Space Between
                </GLink>
            </Link>{' '}
            <Link m={'auto'} color={"white"}>
                <GLink to={'/about'}>
                    About
                </GLink>
            </Link>{' '}
            <Link m={'auto'} color={"white"}>
                <GLink to={'/contact'}>
                    Contact
                </GLink>
            </Link>
            </Flex>
        </Flex>
    )
}

export default Header
